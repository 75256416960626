<template>
  <v-data-table
    :headers="headers"
    :items="payments"
    :items-per-page="rowNumber"
    hide-default-footer
    class="elevation-1"
    dense
  >
    <template v-slot:item.Payment_Date="{ item }">
      <span>{{ item["Payment_Date"] | formatDate }}</span>
    </template>
    <template v-slot:item.staff="{ item }">
      <span
        v-if="search.started"
        v-html="parsedHtml(search.text, item['staff'])"
      />
      <span v-else>{{ item["staff"] }}</span>
    </template>
    <template v-slot:item.Memo="{ item }">
      <span
        v-if="search.started"
        v-html="parsedHtml(search.text, item['Memo'])"
      />
      <span v-else>{{ item["Memo"] }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { rowNumber, headers } from "@/globals";
import { mapGetters } from "vuex";
export default {
  name: "report_data_table",
  data: () => ({
    rowNumber,
    headers
  }),
  computed: {
    searchStarted() {
      return this.search.started;
    },
    ...mapGetters({ search: "search" })
  },
  props: {
    payments: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style scoped></style>
